import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import './ProfessionalBackground.css';
import ExperienceCard from './ExperienceCard';
import roles from '../../../copy/roles';

function ProfessionalBackground() {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('lg'));
  const medium = useMediaQuery(theme.breakpoints.up('md'));
  const small = useMediaQuery(theme.breakpoints.up('sm'));

  const renderHelper = () => {
    if (!large && !medium && !small) {
      return (
        <Typography variant='subtitle1'>
            This will look better in landscape mode or on a larger screen! 
        </Typography>
      )
    }
    return null
  }

  return (
    <div className="">
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h2'>
                Career Chronicles 
            </Typography>
            {renderHelper()}
            <br />
            {roles.map((role) => {
                return <ExperienceCard key={role.role} role={role} />
            })}
        </Box>
    </div>
  );
}

export default ProfessionalBackground;