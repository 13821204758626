import { Typography, Tooltip, Rating, Stack, Card } from '@mui/material';
import { CheckCircleOutline, CircleOutlined } from '@mui/icons-material';

import './ProfessionalBackground.css';

function SkillDisplay({skillLevel, skillName, showLevel = true}) {
    const labels = {
        1: 'Novice',
        2: 'Proficient',
        3: 'Expert',
    };

    function getLabelText(value) {
        return `${labels[value]}`;
    }

    const toolTipContent = () => {
        return (
            <Typography variant='body1'>{getLabelText(skillLevel)}</Typography>
        )
    }

    return (
        <Card className="SkillDisplayCard">
            <Stack direction='row'>
                <Typography sx={{marginRight: '3px'}}>{skillName}</Typography>
                {showLevel && (
                    <Tooltip arrow followCursor title={toolTipContent()}>
                        <Rating
                            name="skill-level"
                            value={skillLevel}
                            max={3}
                            getLabelText={getLabelText}
                            icon={<CheckCircleOutline fontSize="inherit" />}
                            emptyIcon={<CircleOutlined style={{ opacity: 0.55, color: '#493962' }} fontSize="inherit" />}
                            sx={{color: '#d5a021', alignItems: 'center'}}
                        />
                    </Tooltip>
                )}
            </Stack>
        </Card>
    );
}

export default SkillDisplay;