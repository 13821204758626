import { Typography, Tooltip, Avatar, Stack, Button } from '@mui/material';
import { Email, LocationOn } from '@mui/icons-material';

import './ContactDisplay.css';

function ContactDisplay() {
    const styles = {
        width: '64px',
        height: 'auto'
    }

    return (
        <Stack direction='column'>
            <Stack direction="row" alignItems="center">
                <Avatar alt="email" src='email-icon.png' />
                <Button variant='text' href="mailto:hadleyswello@gmail.com">hadleyswello@gmail.com</Button>
            </Stack>
            <Stack direction="row" alignItems="center">
                <Avatar alt="location" src='location-icon.png' />
                <Button variant='text' href="mailto:hadleyswello@gmail.com">Stafford Springs, CT</Button>
            </Stack>
            <Stack direction="row" alignItems="center">
                <Avatar alt="linkedIn" src='linkedin-icon.png' variant='square' />
                <Button target="_blank" rel="noopener" variant='text' href="https://www.linkedin.com/in/hadleyswello/">LinkedIn</Button>
            </Stack>
            <Stack direction="row" alignItems="center">
                <Avatar alt="linkedIn" src='github-logo.svg' variant='square' />
                <Button target="_blank" rel="noopener" variant='text' href="https://github.com/HadleySwello">GitHub</Button>
            </Stack>
        </Stack>
    );
}

export default ContactDisplay;