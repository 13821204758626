import React, { useState } from 'react';
import { createTheme, ThemeProvider, Paper } from '@mui/material';
import { ErrorBoundary } from "react-error-boundary";

import './App.css';
import TopNav from './components/composed/topNav/TopNav';
import MainPage from './components/composed/mainPage/MainPage';
import ProfessionalBackground from './components/composed/professionalBackground/ProfessionalBackground';
import GetToKnowMe from './components/composed/getToKnowMe/GetToKnowMe';
import Endorsements from './components/composed/endorsements/Endorsements';

const root = 'root';
const getToKnowMeRoute = 'getToKnowMeRoute';
const professionalBackgroundRoute = 'professionalBackgroundRoute';
const endorsementsRoute = 'endorsementsRoute';

function App() {
  const [page, setPage] = useState(root)
  const theme = createTheme({
    palette: {
      primary: {
        main: '#344f6b',
      },
      secondary: {
        main: '#d3e1f3',
      },
    },
    typography: {
      fontFamily: [
        'Cormorant Garamond',
      ],
      h1: {
        fontSize: '3em',
        fontStyle: 'normal',
        fontWeight: '400'
      },
      h2: {
        fontSize: '2.4em',
        fontStyle: 'normal',
        fontWeight: 'bold'
      },
      h3: {
        fontSize: '2.1em',
        fontStyle: 'normal',
      },
      h4: {
        fontSize: '1.8em',
        fontStyle: 'normal',
      },
      h5: {
        fontSize: '1.5em',
        fontStyle: 'normal',
        fontWeight: 'bold'
      },
      h6: {
        fontSize: '1.3em',
        fontStyle: 'normal',
      },
      subtitle1: {
        fontSize: '1.2em',
        fontStyle: 'italic',
      },
      subtitle2: {
        fontSize: '1.2em',
        fontStyle: 'italic',
        textDecoration: 'underline'
      },
      body1: {
        fontSize: '120%',
        fontStyle: 'normal',
      },
      body2: {
        fontSize: '120%',
        fontStyle: 'italic',
      },
      button: {
        fontSize: '1.2em',
        fontStyle: 'normal',
        fontWeight: '400',
        textTransform: 'none'
      },
      caption: {
        fontSize: '1.2em',
      },
    }, 
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Garamond';
            font-style: bold;
            font-display: swap;
          }
        `,
      },
    },
  });

  const pages = [
    {title: 'Get To Know Me', slug: getToKnowMeRoute}, 
    {title: 'Professional Background', slug: professionalBackgroundRoute}, 
    {title: 'Endorsements', slug: endorsementsRoute}, 
  ];

  const renderPage = () => {
    switch (page) {
      case root:
        return (<MainPage goToEndorsements={() => setPage(endorsementsRoute)} />)
      case getToKnowMeRoute:
        return <GetToKnowMe />
      case professionalBackgroundRoute:
        return <ProfessionalBackground />
      case endorsementsRoute:
        return <Endorsements />
      default:
        return <MainPage />
    }
  }

  function Fallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
      <div role="alert">
        <p>I'm so sorry! Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <TopNav pages={pages} setPage={setPage} />
        <Paper className='PaperLayout' elevation={3}>
          <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={(details) => {
              // Reset the state of your app so the error doesn't happen again
            }}
          >
              {renderPage()}
          </ErrorBoundary>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default App;
