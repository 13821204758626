import { Tooltip, Avatar, Typography } from '@mui/material';

import './ProfessionalBackground.css';

function LanguageIcon({name, size = '100px'}) {
    const src = () => {
        switch (name) {
            case "Javascript":
                return "javascript-logo.svg"
            case "Jest":
                return "jest-logo.svg"
            case "React":
                return "react-logo.svg"
            case "Jira":
                return "jira-logo.svg"
            case "Zapier":
                return "zapier-logo.svg"
            case "Ruby":
                return "ruby-logo.svg"
            case "PHP":
                return "php-logo.svg"
            case "MySQL":
                return "mysql-logo.svg"
            case "PostgreSQL":
                return "postgresql-logo.svg"
            case "Rails":
                return "rails-logo.svg"
            case "GraphQL":
                return "graphql-logo.svg"
            case "Git":
                return "git-logo.svg"
            case "Jenkins":
                return "jenkins-logo.svg"
            case "JQuery":
                return "jquery-logo.svg"
            case "Kubernetes":
                return "kubernetes-logo.svg"
            case "Redis":
                return "redis-logo.svg"
            case "AWS":
                return "aws-logo.svg"
            case "Docker":
                return "docker-logo.svg"
            case "HubSpot":
                return "hubspot-logo.svg"
            case "Node JS":
                return "node-js-logo.svg"
            case "HTML":
                return "html-logo.svg"
            case "C#":
                return "c-sharp.svg"
            case "CSS":
                return "css-logo.svg"
            case "Java":
                return "java-logo.svg"
            case "Postman":
                return "postman-logo.svg"
            case "Python":
                return "python-logo.svg"
            case "Webpack":
                return "webpack-logo.svg"
            case "GitHub":
                return "github-logo.svg"
            case "Babel":
                return "babel-logo.svg"
            case "Stripe API":
                return "stripe-logo.svg"
            case "VS Code":
                return "vs-code.svg"
            case "Slack":
                return "slack-logo.svg"
            case "Canva":
                return "canva-logo.svg"
            default:
                return null
        }
    }

    const renderToolTipContent = () => {
        return (
            <Typography variant='h4'>{name}</Typography>
        )
    }

    return (
        <Tooltip className='LanguageIcon' arrow title={renderToolTipContent()}>
            <Avatar alt={name} src={src()} variant='square' sx={{ width: size, height: 'auto'}} />
        </Tooltip>
    );
}

export default LanguageIcon;