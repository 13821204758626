import { useState } from 'react';
import { 
    Box, 
    Button,
    Card, 
    CardContent, 
    CardHeader, 
    Grid, 
    ImageList, 
    ImageListItem, 
    Stack, 
    Typography, 
    useMediaQuery,
    useTheme, 
} from '@mui/material';
import Carousel from 'react-multi-carousel';
import uniqueId from 'lodash.uniqueid';

import ContactDisplay from '../shared/ContactDisplay';
import LanguageIcon from '../professionalBackground/LanguageIcon';

import 'react-multi-carousel/lib/styles.css';

import './MainPage.css';
import { endorsements } from '../../../copy/endorsements';
import EndorsementHighlight from '../endorsements/EndorsementHighlight';

function MainPage({goToEndorsements}) {
    const [showArrows, toggleArrows] = useState(false)
    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.up('lg'));
    const medium = useMediaQuery(theme.breakpoints.up('md'));
    const small = useMediaQuery(theme.breakpoints.up('sm'));
    const tiny = useMediaQuery(theme.breakpoints.up('xs'));

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 8,
          slidesToSlide: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
      };

      const logosToDisplay = [
        "AWS",
        "Babel",
        "C#",
        "Canva",
        "CSS",
        "Docker",
        "Git",
        "GitHub",
        "GraphQL",
        "HTML",
        "HubSpot",
        "Java",
        "Javascript",
        "Jenkins",
        "Jest",
        "Jira",
        "JQuery",
        "Kubernetes",
        "MySQL",
        "Node JS",
        "PHP",
        "PostgreSQL", 
        "Postman",
        "Python",
        "Rails",
        "React",
        "Redis",
        "Ruby", 
        "Slack",
        "Stripe API",
        "VS Code",
        "Webpack",
        "Zapier",
      ]

      const responsiveColumnNumber = () => {
        if (large) {
            return 3
        } else if (medium) {
            return 2
        } else if (small) {
            return 1
        } else if (tiny) {
            return 1
        } 
        return 3
    }

  return (
    <div className="">
        <Box sx={{ flexGrow: 1 }}>
            <Stack>
                <Typography variant='h2'>
                    From Education to Engineering
                </Typography>
                <Typography variant='subtitle1'>
                    Former Teacher Turned Fullstack Software Engineer, Coffee Enthusiast, Weekend Forager, and Early Morning Baker!
                </Typography>
            </Stack>
            <br />
            <span onMouseEnter={() => { toggleArrows(true)}} onMouseLeave={() => { toggleArrows(false)}}>
                <Carousel 
                    autoPlay={true}
                    autoPlaySpeed={4000} 
                    centerMode
                    containerClass="carousel-container" 
                    infinite
                    itemClass="carouselItem"
                    responsive={responsive}
                    arrows={showArrows}
                    keyBoardControl
                    draggable
                    swipeable
                    customTransition='transform 4s ease-in-out'
                    transitionDuration='4s'
                >
                    {logosToDisplay.map((logo) => {
                        return <LanguageIcon key={uniqueId(logo)} name={logo} size="auto" />
                    })}
                </Carousel>
            </span>
            <br />
            <Grid container spacing={1}>
                <Grid item sm={12} md={4} lg={4}>
                    <Card>
                        <CardHeader
                            title={<Typography variant='h5'>Get In Touch</Typography>}
                            subheader={<Typography variant='subtitle1'>Feel free to reach out!</Typography>}
                            sx={{
                                background: '#344f6b',
                                color: 'WHITE',
                                borderRadius: '4px, 0px',
                                margin: '-5px',
                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                            }}
                        />
                        <CardContent>
                            <ContactDisplay />
                        </CardContent>
                    </Card>
                    <br />
                    <Card>
                        <CardHeader
                            title={<Typography variant='h5'>Jam Out with Me</Typography>}
                            subheader={<Typography variant='subtitle1'>Check out my Spotify Daylist!</Typography>}
                            sx={{
                                background: '#344f6b',
                                color: 'WHITE',
                                borderRadius: '4px, 0px',
                                margin: '-5px',
                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                            }}
                        />
                        <CardContent>
                            <iframe 
                                style={{borderRadius: "12px"}} 
                                src="https://open.spotify.com/embed/playlist/37i9dQZF1EP6YuccBxUcC1?utm_source=generator" 
                                width="100%" 
                                height="352" 
                                frameBorder="0" 
                                allowFullScreen="" 
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                loading="lazy"
                                title="spotify"
                                >
                            </iframe>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <Card>
                        <CardHeader
                            title={<Typography variant='h5'>Reviews</Typography>}
                            subheader={<Typography variant='subtitle1'>Hear what others have to say!</Typography>}
                            action={((large || medium || small) && <Button variant='text' sx={{color: 'white'}} onClick={() => goToEndorsements()}>View the full reviews here!</Button>)}
                            sx={{
                                background: '#344f6b',
                                color: 'WHITE',
                                borderRadius: '4px, 0px',
                                margin: '-5px',
                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                            }}
                        />
                        <CardContent>
                            <ImageList variant="masonry" cols={responsiveColumnNumber()} gap={8}>
                                {endorsements.map((endorsement) => (
                                    <ImageListItem key={`${endorsement.reviewer}-highlight`}>
                                        <EndorsementHighlight endorsement={endorsement} />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    </div>
  );
}

export default MainPage;