import React, { useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Container, Button, Menu, IconButton, MenuItem  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import './TopNav.css'

function TopNav({pages, setPage}) {
    const [anchorElNav, setAnchorElNav] = useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleNavigation = (slug) => {
        setPage(slug)
    }


  return (
    <AppBar position="static">
        <Container maxWidth="false">
        <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
            >
                {pages.map((page) => (
                <MenuItem key={page.slug} onClick={handleCloseNavMenu}>
                    <Button
                        key={page.title}
                        onClick={() => {handleNavigation(page.slug)}}
                    >
                        {page.title}
                    </Button>
                </MenuItem>
                ))}
            </Menu>
            </Box>
            <Typography
                href='/'
                variant="h1"
                component="a"
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                Hadley Swello
            </Typography>
            <Typography
                variant="h1"
                component="a"
                href="/"
                sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                Hadley Swello
            </Typography>
            <Box className="TopNavContainer" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                    <Button
                        variant='primary'
                        key={page.title}
                        onClick={() => {handleNavigation(page.slug)}}
                        sx={{ my: 2, color: 'white', margin: '5px' }}
                    >
                        {page.title}
                    </Button>
                ))}
            </Box>
        </Toolbar>
        </Container>
    </AppBar>
  );
}
export default TopNav;
