import { Typography, AccordionSummary, AccordionDetails, Stack, Card, CardHeader, CardContent, Accordion } from '@mui/material';
import { ExpandMore, CircleOutlined } from '@mui/icons-material';
import { formatDistanceToNow, format } from 'date-fns';
import { useState } from 'react';
import EndorsementHighlight from './EndorsementHighlight';

function EndorsementCard({endorsement}) {
    const [expanded, setExpanded] = useState(false)

    return (
        <Stack direction='row'>
            <Card className='EndorsementCard'>
                <CardHeader
                    title={<Typography variant='h5'>{endorsement.reviewer}</Typography>}
                    subheader={<Typography variant='subtitle1'>{endorsement.relation}</Typography>}
                    action={<Typography variant='h6'>{`Met in ${format(new Date(endorsement.metIn), "MMM yyy")} (${formatDistanceToNow(new Date(endorsement.metIn))} ago)`}</Typography>}
                    sx={{
                        background: '#344f6b',
                        color: 'WHITE',
                        borderRadius: '4px, 0px',
                        margin: '-5px',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }}
                />
                <CardContent sx={{alignContent: 'center'}}>
                    <EndorsementHighlight fullReview={true} endorsement={endorsement} />
                </CardContent>
            </Card>
        </Stack>
    );
}

export default EndorsementCard;