import { 
    Avatar, 
    Grid, 
    Typography, 
    Box, 
    ImageList, 
    ImageListItem, 
    ImageListItemBar, 
    useTheme, 
    useMediaQuery, 
    Tooltip 
} from '@mui/material';

import './GetToKnowMe.css';
import { formatDistanceToNow } from 'date-fns';
import uniqueId from 'lodash.uniqueid';

function GetToKnowMe() {
    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.up('lg'));
    const medium = useMediaQuery(theme.breakpoints.up('md'));
    const small = useMediaQuery(theme.breakpoints.up('sm'));
    const tiny = useMediaQuery(theme.breakpoints.up('xs'));
    const itemData = [
        {img: 'kibethAndBeanFall.jpeg', title: "Kibeth & Bean enjoy the fall"},
        {img: 'beanOnBlanket.jpeg', title: "Beanie, my miniature Dachshund"},
        {img: 'chickies.jpeg', title: "Our chickens first time out in the grass"},
        {img: 'laurelTrailWithPups.jpeg', title: "The Laurel Sanctuary with my dogs"},
        {img: 'brandonAndI.jpeg', title: "My husband Brandon and I"},
        {img: 'beanieBurrito.jpeg', title: "A Beanie burrito"},
        {img: 'profile-picture.jpg', title: "Hadley Swello"},
        {img: 'lemonCakes.jpeg', title: "Fresh baked lemon cakes"},
        {img: 'homemadePasta.jpeg', title: "Making fresh pasta"},
        {img: 'applePicking.jpeg', title: "Apple picking as a family"},
        {img: 'backyard.jpeg', title: "A cozy fall fire"},
        {img: 'tubing.jpeg', title: "A tubing trip in New Hampshire"},
        {img: 'chocolateCake.jpeg', title: "Fresh baked chocolate cake"},
        {img: 'kibeth.jpeg', title: "Kibeth, my Husky mix"},
        {img: 'foccacia.jpeg', title: "A gardenscape focaccia"},
    ]

    const responsiveColumnNumber = () => {
        if (large) {
            return 4
        } else if (medium) {
            return 3
        } else if (small) {
            return 2
        } else if (tiny) {
            return 1
        } 
        return 4
    }

    const hobbyIcons = [
        {title: 'Baking', src: 'bread-icon.png'},
        {title: 'Cooking', src: 'cooking-icon.png'},
        {title: 'Dungeons & Dragons', src: 'dnd-icon.png'},
        {title: 'Embroidery', src: 'embroidery-icon.png'},
        {title: 'Foraging', src: 'foraging-icon.png'},
        {title: 'Gardening', src: 'gardening-icon.png'},
        {title: 'Hiking', src: 'hike-icon.png'},
        {title: 'Knitting', src: 'knitting-icon.png'},
        {title: 'Music', src: 'music-icon.png'},
        {title: 'Reading', src: 'reading-icon.png'},
        {title: 'Video Games', src: 'video-games-icon.png'},
        {title: 'Watercolor Painting', src: 'art-icon.png'},
    ]

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h2'>
                Hello World!
            </Typography>
            <Typography paragraph>
                With {formatDistanceToNow(new Date('2017/07/01'))} experience as a software engineer, I've developed a knack for blending creativity with code to craft solutions that are not only functional and efficient but also easy to work with for whoever comes next. 
                My journey began in the classroom as a fourth generation teacher, where I honed my skills in communication and patience, essential tools that I now bring to the tech world.
            </Typography>
            <Typography paragraph>
                I'm all about continuous learning and growth, which is why I'm constantly diving into new technologies and approaches. Whether it's diving deep into React or 
                navigating the intricacies of Ruby on Rails, I love the challenge of solving problems and the joy of creating something that makes life a little easier for someone else.                    
            </Typography>
            <Typography paragraph>
                Outside of coding, you'll find me mentoring LGBTQ+ youth in tech, fostering a supportive community where everyone feels empowered to chase their dreams. 
                I believe that technology has the power to bring people together and create positive change, and I'm excited to be a part of that journey.                    
            </Typography>
            <Typography paragraph variant='h3'>Hobbies & Interests</Typography>
            <Grid container spacing={1}>
                {hobbyIcons.map((hobby) => {
                        return (
                            <Grid item sm={1} md={1} lg={1} key={uniqueId(hobby.title)}>
                                <Tooltip className='LanguageIcon' arrow title={<Typography variant='h4'>{hobby.title}</Typography>}>
                                    <Avatar alt={hobby.title} src={hobby.src} variant='square' sx={{ width: '50px', height: 'auto'}} />
                                </Tooltip>
                            </Grid>
                        )
                    })}
            </Grid>
            <Typography paragraph variant='h4'>Listen along with me...</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <iframe 
                        style={{borderRadius: "12px"}} 
                        src="https://open.spotify.com/embed/playlist/37i9dQZF1Fa1IIVtEpGUcU?utm_source=generator"
                        width="100%" 
                        height="352" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                        title="spotify-2023-top">
                    </iframe>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <iframe 
                        style={{borderRadius: "12px"}} 
                        src="https://open.spotify.com/embed/playlist/37i9dQZF1EIfxw8oghwfcN?utm_source=generator"
                        width="100%" 
                        height="352" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                        title="spotify-dinner-party"
                        >
                    </iframe>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <iframe 
                        style={{borderRadius: "12px"}} 
                        src="https://open.spotify.com/embed/playlist/37i9dQZF1EIg08g8RGinF1?utm_source=generator"
                        width="100%" 
                        height="352" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                        title="spotify-garage-punk"
                        >
                    </iframe>
                </Grid>
            </Grid>
            <Typography paragraph variant='h4'>A window into my life...</Typography>
            <ImageList variant="masonry" cols={responsiveColumnNumber()} gap={8}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.title}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}

export default GetToKnowMe;