const roles = [
    {
        company: "Valor Performance, Inc",
        role: "Software Engineer & Scrum Master",
        location: 'Remote',
        startDate: '2020/11/01',
        endDate: '2024/02/01',
        dates: 'Nov 2020 - Feb 2024',
        logo: (<img src='valor-logo.jpg' alt="Valor Logo" width='65px' />),
        logoBackgroundColor: 'black',
        link: 'https://www.valorperform.com/',
        details: [
            "Enhanced team productivity through consistent delivery of high-quality React, Ruby-On-Rails, and PostgreSQL code.", 
            "Maintained codebase stability through thorough code reviews and quality assurance checks.", 
            "Introduced front-end testing suite for three React Apps to improve stability and code quality.", 
            "Implemented Confluence documentation system, reducing onboarding setup time and minimizing help requests.", 
            "Demonstrated agility by quickly learning and implementing new tools, packages, and languages.", 
            "Responded promptly to market shifts by efficiently modifying existing features to meet current business needs.",
            "Fostered a culture of continuous improvement through Engineering Technical Talks.", 
            "Led the team's transition from Kanban to Sprints for a structured work process.", 
            "Organized a remote SCRUM team of 6 technical members with varying backgrounds and timezones.", 
            "Aligned engineering efforts with client requirements through close collaboration with the Product department.", 
            "Lead the process of refining tickets and improving work estimation.",
            "Collaborated with the product owner to plan and prioritize sprint tasks.", 
            "Promoted best practices through regular training sessions and discussions on team expectations.",
            "Assisted in hiring team members and interviewing applicants."
        ],
        skills: [
            {skillName: "Ruby-On-Rails", skillLevel: 3}, 
            {skillName: "GraphQL", skillLevel: 3}, 
            {skillName: "React", skillLevel: 3}, 
            {skillName: "AWS", skillLevel: 1},
            {skillName: "Sidekiq", skillLevel: 3}, 
            {skillName: "Kubernetes", skillLevel: 2}, 
            {skillName: "Redis", skillLevel: 3}, 
            {skillName: "Postgres", skillLevel: 3}, 
            {skillName: "MySQL", skillLevel: 2}, 
            {skillName: "RabbitMQ", skillLevel: 3}, 
            {skillName: "RSPEC", skillLevel: 3}, 
            {skillName: "Jira", skillLevel: 3}, 
            {skillName: "Zapier", skillLevel: 3}, 
            {skillName: "Hubspot", skillLevel: 1}, 
            {skillName: "Docker", skillLevel: 1},
            {skillName: "Git", skillLevel: 3}, 
        ],
        learnMore: {
            relevantLinks: [
                { name: 'Valor\'s Marketing Site', url: 'https://www.valorperform.com/'},
                { name: 'Valor on LinkedIn', url: 'https://www.linkedin.com/company/valorperformance/'}
            ],
            projects: [
                {
                    projectTitle: 'Viewpoints Service Rebuild',
                    toolsUsed: ["Javascript", "React", "GraphQL", "Ruby", "Rails", "PostgreSQL"],
                    participationLevel: 'Implemented',
                    descriptionDetails: {
                        userStory: "As a Valor client I would like my peer review software to be easier to use, more impactful, and less time consuming.",
                        ac: [
                            "Eliminate the sign-up requirement for providing feedback to Valor participants, simplifying the process for new users.",
                            "Reduce the number of clicks needed to leave feedback on someone's round of Viewpoints.",
                            "Improve the user experience for viewing feedback from a round of Viewpoints.",
                            "Update the language and dependencies to the newest stable versions, enabling the use of the latest features."
                        ],
                        conclusion: "Assisted in the rebuild of our peer review software, Viewpoints, achieving significant improvements. We successfully halved the number of clicks required to complete a review, drastically reducing the time needed for the process. Additionally, we collaborated with our content team to re-write the review questions, reducing their number and modifying the scale to better capture data of interest to our clients. This partnership enhanced the user experience and the quality of data collected. These improvements contributed to our already strong 75% open rate for peer review requests and closed the gap between this rate and our previous completion rate of approximately 35%. As a result, we now boast an impressive 80% open rate and an exciting 70% completion rate, marking a substantial enhancement in user engagement and satisfaction."
                    }
                },
                {
                    projectTitle: 'Frontend Testing Initiative',
                    toolsUsed: ["Javascript", "React", "Jest"],
                    participationLevel: 'Designed and Implemented',
                    descriptionDetails: {
                        userStory: "As an engineer at Valor Performance, I recognized the need for a comprehensive test suite for our front-end components to improve the quality and reliability of our code.",
                        ac: [
                            "Updated React/Javascript versions to the newest stable releases to leverage the latest features and improvements.",
                            "Implemented a comprehensive set of basic Jest and Enzyme tests for all base components to ensure their functionality and integrity.",
                            "Developed a robust testing framework to enable functional and display changes to be easily tested and validated."
                        ],
                        conclusion: "Increased our frontend test coverage from 0% to 45% within a year, significantly enhancing the overall quality and reliability of our codebase. This improvement has bolstered developer confidence in code changes, resulting in faster and more efficient development cycles. Additionally, the initiative has led to a noticeable reduction in the number of bugs and issues reported by users, contributing to a more stable and reliable application overall."
                    }
                },
                {
                    projectTitle: 'Automate Helpdesk Request Pipeline',
                    toolsUsed: ["Jira", "Zapier"],
                    participationLevel: 'Designed and Implemented',
                    descriptionDetails: {
                        userStory: "As a Valor Performance Customer Success or Customer Service team member, I would like a single source of truth for our service requests to be funneled through and tracked.",
                        ac: [
                            "Requests from Jira Service Desk, Slack, and Direct Email populate into the Helpdesk Slack channel.",
                            "Comments, status updates, and code changes related to the issue are easily tracked and displayed with the original issue details.",
                            "Client and internal responses are immediately pushed to the correct Slack thread."
                        ],
                        conclusion: "Implemented a Zapier integration to track Jira, Slack, and relevant CS gmail accounts, funneling all details into a single Slack thread. This led to a 30% drop in overall service requests, allowing team members to focus more on long-term solutions."
                    }
                },
            ]
        }
    },
    {
        company: "Wayfair",
        role: "Software Engineer L2",
        location: 'Boston, MA/Remote',
        startDate: '2018/02/01',
        endDate: '2020/11/01',
        dates: 'Feb 2018 - Nov 2020',
        logo: (<img src='wayfair-logo.jpg' alt="Wayfair Logo" width='65px' />),
        logoBackgroundColor: '#7f1a7f',
        link: 'https://www.wayfair.com/',
        details: [
            "Worked on the Naming & Descriptions team to create and maintain systems that manage Wayfair's product description data, using PHP, C#, Java, MySQL, and React.", 
            "Obtained certifications for reviewing Javascript and PHP through Wayfair's certification process.", 
            "Personally developed the product naming software responsible for generating product names throughout the website.", 
            "Expanded the team's Tech Talks into a company-wide program, allowing employees from various departments to present and engage in discussions about the latest technologies, tools, and ongoing projects.", 
            "Co-led the Accessibility@Work Employee Resource Group (ERG), a group that fosters a supportive environment for disabled, chronically ill, and allied employees.", 
            "Orchestrated social and educational events within the community and in partnership with other ERGs and the broader Diversity, Equity, and Inclusion department."
        ],
        skills: [
            {skillName: "PHP", skillLevel: 3}, 
            {skillName: "React/Redux", skillLevel: 3}, 
            {skillName: "GraphQL", skillLevel: 3}, 
            {skillName: "C#/.NET", skillLevel: 2}, 
            {skillName: "SQL", skillLevel: 2}, 
            {skillName: "Java", skillLevel: 1},
            {skillName: "Git", skillLevel: 3}, 
            {skillName: "Kubernetes", skillLevel: 2}, 
            {skillName: "MySQL", skillLevel: 2},
            {skillName: "Jest", skillLevel: 2}, 
            {skillName: "Enzyme", skillLevel: 2}, 
            {skillName: "Docker", skillLevel: 2},
        ],
        learnMore: {
            relevantLinks: [
                { name: 'Wayfair.com', url: 'https://www.wayfair.com/'},
                { name: 'Wayfair on LinkedIn', url: 'https://www.linkedin.com/company/wayfair/'}
            ],                
            projects: [
                {
                    projectTitle: 'Product Name Generator (Internal Tool)',
                    toolsUsed: ["React", "PHP", "MySQL"],
                    participationLevel: 'Architected and Implemented',
                    descriptionDetails: {
                        userStory: "As a Wayfair Product Owner, I need a streamlined and efficient solution to automatically generate unique and descriptive names for our extensive range of 14 million products.",
                        ac: [
                            "Product names can include any product details (color, material, etc.) in the generated name.",
                            "Product names are unique on platform.",
                            "Product owners can preview the name that will be generated for a given sku.",
                            "Product name formulas can be copied and used in other sections for easy preview and tweaks of existing formulas."
                        ],
                        conclusion: "Developed an internal tool enabling product owners to create naming formulas for products. This streamlined process significantly reduced manual hours previously spent on naming products. The tool empowers product owners to focus on impactful work by facilitating quick trials and previews of names, ensuring alignment with product identities across various SKUs. It has not only improved efficiency but also enhanced the overall product naming process."
                    }
                },
            ]
        }
    },
    {
        company: "Poughkeepsie Child Development Center",
        role: "Lead Preschool Teacher",
        location: 'Poughkeepsie, NY',
        startDate: '2015/09/01',
        endDate: '2017/06/01',
        dates: 'Sept 2015 - Jun 2017',
        logo: (<img src='pcdc-logo.jpeg' alt="pcdc Logo" width='65px' />),
        logoBackgroundColor: 'white',
        link: 'https://www.facebook.com/PCDC159/',
        details: [
            "Effectively communicated intricate ideas to individuals unfamiliar with the topic in a clear and straightforward way, fostering strong communication skills vital for collaborating with cross-functional teams.",    
            "Created engaging lessons and projects suitable for diverse audiences and skill levels, demonstrating creativity and the ability to tailor solutions to specific needs.",  
            "Managed behavioral challenges with a calm approach that fostered teamwork and community development, showcasing strong leadership and conflict resolution skills applicable in managing project teams and addressing various challenges.", 
            "Bridged the gap in communication between different cultural and linguistic barriers, highlighting adaptability and cultural sensitivity, essential in a diverse and collaborative professional environment." 
        ],
        skills: [
            {skillName: "Behavioral Management", skillLevel: 3}, 
            {skillName: "Communication", skillLevel: 3}, 
            {skillName: "Curriculum Development", skillLevel: 2}, 
            {skillName: "Multicultural Awareness", skillLevel: 2}, 
            {skillName: "Team Building", skillLevel: 3},
            {skillName: "Team Management", skillLevel: 3}, 
            {skillName: "Trainings & Education", skillLevel: 3}, 
        ],
    },
    {
        company: "The Millbrook Winery",
        role: "Tour Guide/Sommelier",
        location: 'Millbrook, NY',
        startDate: '2013/05/01',
        endDate: '2017/01/01',
        dates: 'May 2013 - Jan 2017',
        logo: (<img src='winery-logo.jpeg' alt="pcdc Logo" width='65px' />),
        logoBackgroundColor: 'white',
        link: 'https://www.millbrookwine.com/',
        details: [
            "Managed tour and tasting schedules to optimize client satisfaction and maintain efficient workflow for staff.",
            "Provided personalized wine recommendations, enhancing client experience and driving sales.",
            "Demonstrated exceptional customer service during busy winery events, ensuring smooth operations and positive guest interactions.",
            "Represented the winery at various events and conferences, fostering community relations and promoting brand awareness.",
        ],
        skills: [
            {skillName: "Public Speaking", skillLevel: 3}, 
            {skillName: "Communication", skillLevel: 3}, 
            {skillName: "Team Building", skillLevel: 3},
            {skillName: "Time Management", skillLevel: 3}, 
            {skillName: "Trainings & Education", skillLevel: 3}, 
        ],
    },
]

export default roles;