export const endorsements = [
    {
        reviewer: "Caitlin Dalton",
        metIn: '2013/05/01',
        review: `To Whom It May Concern,

            I am writing this letter to express my full confidence in Hadley Swello as a software engineer. I have had the privilege to work with her in my product role for over a year, and during this time, I have seen first-hand her exceptional coding skills.
            
            Hadley is a highly dedicated and skilled software engineer who excels in solving complex challenges. Her solutions are always thorough and enhance the overall performance of the team. Moreover, she possesses a wealth of institutional knowledge that makes her extremely effective at her job. Even during her last days with our team, her knowledge was leveraged to significantly reduce the scope of a code debt ticket during estimation.
            
            Besides being an excellent programmer, Hadley is also a kind, thoughtful, and respectful employee who goes above and beyond the call of duty. She is always available to answer questions, mentor, and oversee newer Engineers.
            
            I would wholeheartedly recommend Hadley as a software engineer. She is a dynamic and skilled programmer who would be a valuable asset to any team.
            
            Sincerely,
            
            Caitlin Dalton`,
        relation: "Coworker: Valor Performance, Inc/The Millbrook Winery",
        highlight: "I would wholeheartedly recommend Hadley as a software engineer. She is a dynamic and skilled programmer who would be a valuable asset to any team."
    },
    {
        reviewer: "Nick Adams",
        metIn: '2022/10/01',
        review: `I cannot understate how much Hadley brings to any team she is a part of, even if you don't account for her considerable technical skill as a software engineer. During my time with her at Valor, she wrangled a fully-remote team of introverts into an organized scrum team, fostering an environment where we constantly used direct and intentional communication to support one another. I must not undersell the amount of invisible work she did to keep the wheels greased, but you would think she can organize or prepare for sprint and demos just by willing it into being. She's a joy to be around, a blessing to team productivity, and a selfless individual always willing to collaborate. She's someone not content to just work alongside a team, but driven to work with and as a team.
            If you find yourself with the opportunity to hire Hadley, you're doing a disservice to your team and your organization by passing her up. Had I been in charge, she would still be here.`,
        relation: "Coworker/Mentee: Valor Performance, Inc",
        highlight: "During my time with her at Valor, she wrangled a fully-remote team of introverts into an organized scrum team, fostering an environment where we constantly used direct and intentional communication to support one another."
    },
    {
        reviewer: "Erica Nieves",
        metIn: '2023/06/01',
        review: `I had the pleasure of working alongside Hadley, a highly dedicated and skilled software engineer who excels in solving complex challenges. Her solutions are consistently thorough and contribute significantly to the overall performance of the team. Hadley possesses an extensive amount of experience and expertise that makes her extremely effective in her role. Beyond her technical prowess, she is an invaluable team member known for her exceptional thoughtfulness and delightful personality. Her warm and inviting nature not only creates a comfortable environment for collaboration but also establishes her as the go-to person for assistance. Hadley's ability to break down her work for those without an engineering background showcases her exceptional communication skills. Even though I didn't work alongside her as an engineer, I still learned a lot from her, and her insights were invaluable for the entire team. Her combination of technical expertise, positive team impact, and effective communication make her a truly exceptional professional, and I wholeheartedly recommend her for any professional endeavor.`,
        relation: "Coworker: Valor Performance, Inc",
        highlight: "Her warm and inviting nature not only creates a comfortable environment for collaboration but also establishes her as the go-to person for assistance."
    },
    {
        reviewer: "Jamie Birney",
        metIn: '2020/10/01',
        review: `I was fortunate to work with Hadley for over three years at Valor. She was passionate about the company and the job, and doing a great job. She developed an in-depth knowledge of the codebase and was able to think through all the side-effects of a change. She was also a great teammate, leading trainings for the team and always helping us make improvements to the development process. She was the definition of a team player, and a big part of our team's success.`,
        relation: "Manager: Valor Performance, Inc",
        highlight: "She was also a great teammate, leading trainings for the team and always helping us make improvements to the development process."
    },
    {
        reviewer: "Nancy Blayney",
        metIn: '2009/05/01',
        review: `Having known Hadley since before she embarked on her journey in the tech industry, I've witnessed her impressive growth and dedication firsthand. She has 7 years of experience across a broad range of tech stacks, showcasing not just her technical prowess but also her exceptional leadership abilities. Hadley has an extraordinary attention to detail and a strong ability to adapt to new technologies and situations, which she pairs with a proactive attitude toward embracing challenges and a sense of humor rivaling that of Monty Python.

            Her positive outlook and initiative, and her early background as a preschool teacher, have allowed her to excel in roles requiring both technical and leadership skills, notably serving effectively as both a scrum master and an engineer. Her contributions not only drive project successes but also enhance team dynamics and productivity. Hadley is a joy to collaborate with and possesses a unique blend of skills that make her an outstanding asset to any team or project.
            
            In short - Hadley has both the technical chops and the top-tier EQ to contribute to an IT team in any number of ways.`,
        relation: "Coworker: The Millbrook Winery",
        highlight: "In short - Hadley has both the technical chops and the top-tier EQ to contribute to an IT team in any number of ways."
    },
    {
        reviewer: "Marco Gonzalez",
        metIn: '2022/01/01',
        review: `I had the pleasure of working with Hadley and I can confidently attest to her exceptional abilities in numerous capacities. During our regular pair programming sessions, Hadley's expertise shone brightly. Her ability to dissect intricate problems, offering thorough explanations that facilitated our team's collective understanding. Hadley's patient and methodical approach not only enhanced our technical capabilities but also fostered a culture of continuous learning and growth.

            As our Scrum Master, Hadley demonstrated remarkable leadership and organizational acumen, ensuring that our team remained focused and on track throughout our projects. Her skillful utilization of clear visual aids, coupled with effective Jira dashboard management, greatly enhanced communication and collaboration among team members.
            
            In addition to her technical expertise, Hadley's positive attitude was infectious. Even during challenging situations, she maintained a can-do attitude that inspired those around her. Her optimism and enthusiasm were instrumental in keeping team morale high and driving us towards our goals.
            
            Overall, Hadley is a consummate professional who brings a wealth of skills and qualities to any team. I highly recommend her for any role where her talents as a software engineer and Scrum Master can shine.`,
        relation: "Coworker: Valor Performance, Inc",
        highlight: "Overall, Hadley is a consummate professional who brings a wealth of skills and qualities to any team."
    },

    {
        reviewer: "Heather Moran",
        metIn: '2023/07/01',
        review: `Hadley has an organized mind and a refreshing personality. In my experience working with her, she demonstrated her ability to effectively break work into smaller executable pieces, refine concepts brought forward by non-technical teammates, and explain technical processes and concepts back to those same teammates in a way that turned on lightbulbs. She is a positive person who worked to build culture as much as the product. It would be my pleasure to work with her again!`,
        relation: "Coworker: Valor Performance, Inc",
        highlight: "She ... explained technical processes and concepts back to teammates in a way that turned on lightbulbs."
    },
    {
        reviewer: "Andrew Chupp",
        metIn: '2022/08/01',
        review: `I have worked first hand with Hadley for about 1.5 years. She is an extremely talented software engineer, often solving problems in half the time other engineers would take. She also (voluntarily) took on duties of Agile Scrum Master, showing initiative and dedication to helping the company run as smoothly as possible. She is also an exceptional educator, having helped me (the data scientist) will all of the engineering-based challenges I confronted with a patience I did not expect. Hadley is truly a tremendous asset to any team she joins.`,
        relation: "Coworker: Valor Performance, Inc",
        highlight: "She is an extremely talented software engineer, often solving problems in half the time other engineers would take."
    },
]