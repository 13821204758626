import { useState } from 'react';
import { Typography, Accordion, AccordionDetails, AccordionSummary, Button, Stack, Card, CardHeader, CardContent, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import uniqueId from 'lodash.uniqueid';

import './ProfessionalBackground.css';
import SkillDisplay from './SkillDisplay';
import LanguageIcon from './LanguageIcon';

function LearnMoreAccordion({learnMoreAbout}) {
    const [expanded, setExpanded] = useState(false);

    const renderRelevantLinks = () => {
        if (learnMoreAbout.relevantLinks.length === 0) {
            return null
        }
        return (
            <Stack direction='row' justifyContent="space-between">
                <Typography variant='h4'>Relevant Links</Typography>
                <Stack direction='row'>
                    {learnMoreAbout.relevantLinks.map((link) => {
                        return <Button key={uniqueId(link)} sx={{marginLeft: '4px'}} variant='contained' target="_blank" rel="noopener" href={link.url}>{link.name}</Button>
                    })}
                </Stack>
            </Stack>
        )
    }

    const renderProjects = () => {
        if (learnMoreAbout.projects.length === 0) {
            return null
        }
        return (
            <Stack direction='column'>
                <Typography variant='h4'>Projects</Typography>
                <br/>
                {learnMoreAbout.projects.map((project) => {
                    return (
                        <Card key={project.projectTitle} className="SkillDisplayCard">
                            <CardHeader
                                title={<Typography variant='h5'>{project.projectTitle}</Typography>}
                                subheader={<Typography variant='subtitle1'>{`${project.participationLevel}`}</Typography>}
                                sx={{
                                    background: '#344f6b',
                                    color: 'WHITE',
                                    // borderBottom: '1px solid #344f6b', 
                                    borderRadius: '4px, 0px',
                                    margin: '-5px',
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                                }}
                            />
                            <CardContent>
                                <Stack direction="row" justifyContent='center'>
                                    {project.toolsUsed.map((tool) => {
                                        return <LanguageIcon key={uniqueId(tool)} name={tool} />
                                    })}
                                </Stack>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant='h6' noWrap>User Story:</Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='body1'>{project.descriptionDetails.userStory}</Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h6' noWrap>Acceptance Criteria:</Typography>
                                        <ul>
                                            {project.descriptionDetails.ac.map((criterion) => {
                                                return (
                                                    <li key={uniqueId(criterion)}>
                                                        <Typography variant='body1'>{criterion}</Typography>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Grid>
                                    <Grid>
                                        <Typography variant='h6' noWrap>Results:</Typography>
                                        <ul>
                                            <li>
                                                <Typography variant='body1'>{project.descriptionDetails.conclusion}</Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Accordion sx={{width: '100%'}} expanded={expanded} onChange={() => {setExpanded(!expanded)}}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant='subtitle2' sx={{ width: '33%', flexShrink: 0 }}>
                    Learn More
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>View projects, relevant links, and other specifics.</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {renderRelevantLinks()}
                {renderProjects()}
            </AccordionDetails>
        </Accordion>
    );
}

export default LearnMoreAccordion;