import { Typography, Card, CardContent, CardActions, Button, CardHeader, Avatar, Grid, ImageList, useMediaQuery, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';
import uniqueId from 'lodash.uniqueid';

import './ProfessionalBackground.css';
import SkillDisplay from './SkillDisplay';
import LearnMoreAccordion from './LearnMoreAccordion';

function ExperienceCard({role}) {
    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Card key={role.role} className="ExperienceCard">
        <CardHeader
            avatar={
                <Avatar sx={{ bgcolor: role.logoBackgroundColor, width: 75, height: 75 }} aria-label="company-logo">
                    {role.logo}
                </Avatar>
            }
            title={<Typography variant='h3'>{role.company}</Typography>}
            subheader={<Typography variant='subtitle1'>{`${role.location} | ${role.dates} (${formatDistance(new Date(role.startDate), new Date(role.endDate))})`}</Typography>}
            action={
                <Button target="_blank" rel="noopener" size="small" href={role.link}>VISIT SITE</Button>
            }
        />
        <CardContent>
            <Grid container>
                <Grid item sm={12} md={8}>
                    <Typography variant='h4'>
                        {role.role}
                    </Typography>
                    <ul>
                        {role.details.map((detail) => {
                            return <li key={uniqueId(detail)}><Typography variant='body1'>{detail}</Typography></li>
                        })}
                    </ul>
                </Grid>
                <Grid lg={4} item>
                    <Typography variant='h4'>
                        Skills Acquired
                    </Typography>
                    <ImageList sx={{paddingBottom: '4px'}} cols={large ? 2 : 1} variant="masonry">
                        {role.skills.map((detail) => {
                            return (
                                <SkillDisplay key={uniqueId(detail)} skillLevel={detail.skillLevel} skillName={detail.skillName} />
                            )
                        })}
                    </ImageList>
                </Grid>
            </Grid>
        </CardContent>
        <CardActions>
            {role.learnMore ? <LearnMoreAccordion learnMoreAbout={role.learnMore} /> : null}
        </CardActions>
    </Card>
  );
}

export default ExperienceCard;