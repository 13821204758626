import { Typography, CardHeader, CardContent, Stack, Card } from '@mui/material';
import { CheckCircleOutline, CircleOutlined } from '@mui/icons-material';

import './Endorsements.css'

function EndorsementHighlight({endorsement, fullReview = false}) {

    return (
        <Card className='HighlightCard'>
            <CardContent>
                <Typography paragraph variant='h6'>"{fullReview ? endorsement.review : endorsement.highlight}"</Typography>
                {fullReview ? null : <Typography align='right' variant='subtitle1'>-{endorsement.reviewer}: {endorsement.relation}</Typography>}
            </CardContent>
        </Card>
    );
}

export default EndorsementHighlight;