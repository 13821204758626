import { Typography, Button, Stack, Card } from '@mui/material';

import { endorsements } from '../../../copy/endorsements';
import EndorsementCard from './EndorsementCard';

function Endorsements() {
    return (
        <span>
            <Stack direction='row' sx={{justifyContent: "space-between"}}>
                <Typography variant='h2'>What have others said about me?</Typography>
                <Button target="_blank" rel="noopener" variant='text' href="https://www.linkedin.com/in/hadleyswello/details/recommendations/">View These on LinkedIn</Button>
            </Stack>
            <br />
            <Stack>
                {endorsements.map((endorsement) => {
                    return <EndorsementCard key={endorsement.reviewer} endorsement={endorsement} />
                })}
            </Stack>
        </span>
    );
}

export default Endorsements;